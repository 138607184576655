.main {
    font-family: 'League Spartan', arial, helvetica, sans-serif;
}

.sub {
    font-family: 'Montserrat', arial, sans-serif;
}

.bg-1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), url("https://img1.wsimg.com/isteam/stock/YowxQ7Y/:/rs=w:1534,m");
    background-position: center;
    background-size: auto, cover;
}

.circle-1 {
    object-fit: cover;
    border-radius: 50%;
}